
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div *ngIf="(task && !isEditMode) || (taskCpy && isEditMode)" vpScrollList (onUserScroll)="setHeader($event);closeOverlay()" class="task-detail-info vp-vnctask-details" [class.edit-mode-padding]="isEditMode && isMobileDevice">
    <div id="task-detail-header" class="header" [style.background-color]="getPriorityColor(task.priority)">
        <div *ngIf="!isEditMode && isMobileDevice" class="detail-header">
            <div class="buttons-header">
                <div class="back-buttons">
                    <button class="brand-color mat-button" id="task-detail-back-btn" (click)="backFromDetail()">
                        <svg width="20px" height="20px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/keyboard-arrow-left-new</title>
                            <g id="Icon/product/keyboard-arrow-left-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M15.6568542,7.29289322 C16.0473785,7.68341751 16.0473785,8.31658249 15.6568542,8.70710678 L11.8284271,12.5355339 L15.6568542,16.363961 C16.0473785,16.7544853 16.0473785,17.3876503 15.6568542,17.7781746 C15.26633,18.1686989 14.633165,18.1686989 14.2426407,17.7781746 L9,12.5355339 L14.2426407,7.29289322 C14.633165,6.90236893 15.26633,6.90236893 15.6568542,7.29289322 Z" id="Path" fill="#ffffff"></path>
                            </g>
                        </svg>
                    </button>
                </div>
                <div *ngIf="!isFixedHeader" class="opts-buttons">
                    <button class="brand-color mat-button" id="task-detail-done-btn" (click)="onCompleteTask()">
                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/check-circle</title>
                            <g id="Icon/product/check-circle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
                                    <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M8,15 L3,10 L4.41,8.59 L8,12.17 L15.59,4.58 L17,6 L8,15 L8,15 Z" id="Shape"></path>
                                </g>
                            </g>
                        </svg>
                    </button>
                    <button class="brand-color mat-button" id="task-detail-done-btn" (click)="onDuplicateTask()">
                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/content-copy</title>
                            <g id="Icon/product/content-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Icon-24px" transform="translate(3.000000, 2.000000)" fill="#8B96A0">
                                    <path d="M16,3 C17.1,3 18,3.9 18,5 L18,5 L18,19 C18,20.1 17.1,21 16,21 L16,21 L5,21 C3.9,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.9,3 5,3 L5,3 Z M16,5 L5,5 L5,19 L16,19 L16,5 Z M11.9359236,0 C12.4882083,0 12.9359236,0.44771525 12.9359236,1 C12.9359236,1.51283584 12.5498834,1.93550716 12.0525447,1.99327227 L11.9359236,2 L4.33333333,2 C3.09836327,2 2.08747935,2.95942684 2.00538306,4.17357901 L2,4.33333333 L2,16.0165979 C2,16.5688826 1.55228475,17.0165979 1,17.0165979 C0.487164161,17.0165979 0.0644928393,16.6305577 0.00672773133,16.133219 L0,16.0165979 L0,4.33333333 C0,2.01048865 1.82765421,0.11451548 4.12337942,0.00499702767 L4.33333333,0 L11.9359236,0 Z" id="Combined-Shape"></path>
                                </g>
                            </g>
                        </svg>
                    </button>
                    <button class="brand-color mat-button" id="task-detail-delete-btn" (click)="onDeleteTask()">
                        <svg width="22px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/delete-new</title>
                            <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#ffffff"></path>
                            </g>
                        </svg>
                    </button>
                </div>
                <div class="fixed-subject-info" *ngIf="isFixedHeader">
                    <div class="title" [class.highlight-header]="taskHighlightFields && taskHighlightFields.subject">{{ task.subject }}</div>
                </div>
                <div class="more-button" *ngIf="isFixedHeader">
                    <button class="brand-color mat-button" id="task-detail-more-btn" #bottomRightButton [matMenuTriggerFor]="taskDetailHeaderPopover">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/more-vert</title>
                            <g id="Icon/product/more-vert" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Icon-24px" transform="translate(10.000000, 4.000000)" fill="#ffffff">
                                    <path d="M2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 L2,4 Z M2,6 C0.9,6 0,6.9 0,8 C0,9.1 0.9,10 2,10 C3.1,10 4,9.1 4,8 C4,6.9 3.1,6 2,6 L2,6 Z M2,12 C0.9,12 0,12.9 0,14 C0,15.1 0.9,16 2,16 C3.1,16 4,15.1 4,14 C4,12.9 3.1,12 2,12 L2,12 Z" id="Shape"></path>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
            <div *ngIf="!isFixedHeader" class="subject-header">
                <div class="assigned-icon">
                    <vp-avatar [jid]="task.assigned_to.jid" [avatarId]="task.assigned_to.id" [user]="{ name: task.assigned_to && task.assigned_to.name ? task.assigned_to.name : '', avatarURL: task.userAvatar }"> </vp-avatar>
                </div>
                <div class="text-header">
                    <div><span [class.highlight-header]="taskHighlightFields && taskHighlightFields.subject">{{ task.subject }}</span></div>
                    <div class="flex">
                        <div class="property-text-header">
                            <div class="property-text">
                                <svg width="13px" height="13px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <title>Icon/product/assignment-late</title>
                                    <g id="Icon/product/assignment-late" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#8B96A0">
                                            <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M10,17 L8,17 L8,15 L10,15 L10,17 L10,17 Z M10,13 L8,13 L8,7 L10,7 L10,13 L10,13 Z M9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 L9,4 Z" id="Shape"></path>
                                        </g>
                                    </g>
                                </svg>
                                <span [class.highlight-header]="taskHighlightFields && taskHighlightFields.priority">{{ task.priority?.name | uppercase | translate }}</span>
                            </div>
                            <div class="property-text">
                                <svg width="13px" height="13px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <title>Icon/product/user-new</title>
                                    <g id="Icon/product/user-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M6,17.85 L6,19 C6,19.5522847 5.55228475,20 5,20 C4.44771525,20 4,19.5522847 4,19 L4,17.85 L4,17.85 C4,14.8766994 8.5673946,13 12.5,13 C16.3506761,13 20.8099674,14.7993182 20.9940999,17.6656036 L21,17.85 L21,19 C21,19.5522847 20.5522847,20 20,20 C19.4477153,20 19,19.5522847 19,19 L19,17.85 L19,17.85 C19,16.540378 15.79428,15.1542503 12.9670805,15.0119699 L12.7324336,15.0030208 L12.5,15 C9.64886306,15 6.18754404,16.3913943 6.00734383,17.740716 L6,17.85 Z M12.507014,3 C14.9932903,3 17,5.01382241 17,7.5 C17,9.98617759 14.9932903,12 12.507014,12 C10.0193052,12 8,9.98473341 8,7.5 C8,5.01526659 10.0193052,3 12.507014,3 Z M12.507014,5 C11.1229794,5 10,6.12073341 10,7.5 C10,8.87926659 11.1229794,10 12.507014,10 C13.8871336,10 15,8.88318908 15,7.5 C15,6.11681092 13.8871336,5 12.507014,5 Z" id="Shape" fill="#ffffff"></path>
                                    </g>
                                </svg>
                                <span [class.highlight-header]="taskHighlightFields && taskHighlightFields.assigned_to">{{ task.assigned_to ? task.assigned_to.name : 'NONE' | translate }}</span>
                            </div>
                        </div>
                        <div class="edit-button-header" *ngIf="!isEditMode">
                            <button id="task_edit_btn" class="edit-button" [style.background-color]="getEditIconColor(task.priority)" mat-button mat-fab
                                (click)="onEdit(task)">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <title>Icon/product/edit</title>
                                    <g id="Icon/product/edit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Icon-24px" transform="translate(3.000000, 2.997500)" fill="#ffffff">
                                            <path d="M0,14.2525 L0,18.0025 L3.75,18.0025 L14.81,6.9425 L11.06,3.1925 L0,14.2525 L0,14.2525 Z M17.71,4.0425 C18.1,3.6525 18.1,3.0225 17.71,2.6325 L15.37,0.2925 C14.98,-0.0975 14.35,-0.0975 13.96,0.2925 L12.13,2.1225 L15.88,5.8725 L17.71,4.0425 L17.71,4.0425 Z" id="Shape"></path>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isEditMode && !isMobileDevice" class="detail-header">
            <div class="desktop-detail-header">
                <div class="subject-title-header">
                    <mat-icon class="material-icons">event_available</mat-icon>
                    <span class="title" [class.highlight-header]="taskHighlightFields && taskHighlightFields.subject">{{ task.subject }}</span>
                </div>
                <div class="property-detail-header">
                    <div class="property-header">
                        <div class="assignto-header">
                            <vp-avatar [jid]="task.assigned_to.jid" [avatarId]="task.assigned_to.id" [user]="{ name: task.assigned_to && task.assigned_to.name ? task.assigned_to.name : '', avatarURL: task.userAvatar }"> </vp-avatar>
                            <span [class.highlight-header]="taskHighlightFields && taskHighlightFields.assigned_to">{{ task.assigned_to ? task.assigned_to.name : 'NONE' | translate }}</span>
                        </div>
                        <div class="priority-header">
                            <svg width="13px" height="13px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/assignment-late</title>
                                <g id="Icon/product/assignment-late" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#8B96A0">
                                        <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M10,17 L8,17 L8,15 L10,15 L10,17 L10,17 Z M10,13 L8,13 L8,7 L10,7 L10,13 L10,13 Z M9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 L9,4 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                            <span [class.highlight-header]="taskHighlightFields && taskHighlightFields.priority">{{ task.priority?.name | uppercase | translate }}</span>
                        </div>
                    </div>
                    <div class="edit-button-header" *ngIf="!isEditMode">
                        <button class="edit-button" [style.background-color]="getEditIconColor(task.priority)" mat-button mat-fab
                            (click)="onEdit(task)">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/edit</title>
                                <g id="Icon/product/edit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Icon-24px" transform="translate(3.000000, 2.997500)" fill="#ffffff">
                                        <path d="M0,14.2525 L0,18.0025 L3.75,18.0025 L14.81,6.9425 L11.06,3.1925 L0,14.2525 L0,14.2525 Z M17.71,4.0425 C18.1,3.6525 18.1,3.0225 17.71,2.6325 L15.37,0.2925 C14.98,-0.0975 14.35,-0.0975 13.96,0.2925 L12.13,2.1225 L15.88,5.8725 L17.71,4.0425 L17.71,4.0425 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isEditMode" class="detail-header">
            <div class="buttons-header">
                <div class="back-buttons">
                    <button id="task-edit-close-btn" class="brand-color mat-button" (click)="backToDetail()">
                        <svg width="20px" height="20px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/keyboard-arrow-left-new</title>
                            <g id="Icon/product/keyboard-arrow-left-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M15.6568542,7.29289322 C16.0473785,7.68341751 16.0473785,8.31658249 15.6568542,8.70710678 L11.8284271,12.5355339 L15.6568542,16.363961 C16.0473785,16.7544853 16.0473785,17.3876503 15.6568542,17.7781746 C15.26633,18.1686989 14.633165,18.1686989 14.2426407,17.7781746 L9,12.5355339 L14.2426407,7.29289322 C14.633165,6.90236893 15.26633,6.90236893 15.6568542,7.29289322 Z" id="Path" fill="#ffffff"></path>
                            </g>
                        </svg>
                    </button>
                </div>
                <div class="edit-task-header">{{ 'EDIT_TASK' | translate }}</div>
            </div>
            <div class="subject-header">
                <div class="subject-textfield">
                    <mat-form-field>
                        <textarea matInput rows="2" maxrows="3" [(ngModel)]="subject" #editTaskSubject></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div id="task-detail-body" class="detail-body" [class.task-detail-line-1]="!isEditMode && commentListExpanded" [class.comment-collapsed]="!commentListExpanded">
        <div class="detail-info-table" [class.de-german-read]="isDe" [class.en-read]="!isDe" *ngIf="!isEditMode">
            <div class="task-completed-icon" *ngIf="task.status && (task.status.name === 'Closed' || task.status.name === 'Completed')">
                <img src="assets/images/Task_completed.svg" />
            </div>
            <!-- Description -->
            <div *ngIf="(task.description ? task.description.length > 0: false)">
                <div class="table-row">
                    <div id="description" [innerHTML]="descriptionHTML" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.description" [class.desc-read-more]="descLines > 8 && !descExpanded"></div>
                    <div *ngIf="!task.description" class="padding-10"><span>{{ 'NO_DESCRIPTION' | translate }}</span></div>
                </div>
                <div class="table-row" *ngIf="descLines > 8">
                  <div class="desc-expand-div" (click)="descExpanded ? !descExpanded = false: descExpanded = true">
                    <span *ngIf="!descExpanded">{{ 'READ_MORE' | translate }}</span>
                    <span *ngIf="descExpanded">{{ 'READ_LESS' | translate }}</span>
                  </div>
                </div>
            </div>
            <!-- Separater Div-->
            <div class="separater-expand-div" (click)="propertyListExpanded ? !propertyListExpanded = false: propertyListExpanded = true">
                <div class="item-icon">
                    <svg *ngIf="!propertyListExpanded" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/arrow-drop-down</title>
                        <g id="Icon/product/arrow-drop-down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(7.000000, 9.000000)" fill="#8B96A0">
                                <polygon id="Shape" points="0 0 5 5 10 0"></polygon>
                            </g>
                        </g>
                    </svg>
                    <svg *ngIf="propertyListExpanded" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/arrow-drop-up</title>
                        <g id="Icon/product/arrow-drop-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(7.000000, 9.000000)" fill="#8B96A0">
                                <polygon id="Shape" points="0 5 5 0 10 5"></polygon>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="item-title">{{ 'PROPERTIES' | translate }}:</div>
            </div>
            <!-- Start Date -->
            <div *ngIf="propertyListExpanded" class="padding-14">
                <div class="table-row" *ngIf="task.start_date">
                    <div class="label-table-cell">
                        <mat-icon>today</mat-icon>
                        <span class="detail-label-text">{{ 'TASK_START_DATE' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.start_date">{{ getDateWithFormat(task.start_date, "dd MMM. y") }}</span>
                    </div>
                </div>
                <!-- Due Date -->
                <div class="table-row" *ngIf="task.due_date">
                    <div class="label-table-cell">
                        <mat-icon>event</mat-icon>
                        <span class="detail-label-text">{{ 'TASK_DUE_DATE' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span *ngIf="task.due_date" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.due_date">{{ getDateWithFormat(task.due_date, "dd MMM. y") }}</span>
                    </div>
                </div>
                <!-- Status -->
                <div class="table-row" *ngIf="task.status">
                    <div class="label-table-cell">
                        <div class="icon-container-block">
                            <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/assignment-turned-in</title>
                                <g id="Icon/product/assignment-turned-in" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#8B96A0">
                                        <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M7,16 L3,12 L4.41,10.59 L7,13.17 L13.59,6.58 L15,8 L7,16 L7,16 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <span class="detail-label-text">{{ 'STATUS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span *ngIf="task.status && (task.status.name === 'Closed' || task.status.name === 'Completed')" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.status">{{'STATUS_COMPLETED' | translate}}</span>
                        <span *ngIf="task.status && (task.status.name === 'In Progress')" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.status">{{'STATUS_INPROGRESS' | translate}}</span>
                        <span *ngIf="task.status && (task.status.name === 'New')" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.status">{{'STATUS_NEW' | translate }}</span>
                    </div>
                </div>
                <!-- Repeat -->
                <div class="table-row" *ngIf="task.repeat">
                    <div class="label-table-cell">
                        <div class="icon-container-block">
                            <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                             <title>Icon/product/autorenew</title>
                                 <g id="Icon/product/autorenew" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                     <g id="Icon-24px" transform="translate(4.000000, 1.480291)" fill="#8B96A0">
                                         <path d="M8,6.52075285 C8,7.07246132 8.31387329,7.20583612 8.70591205,6.81379735 L12,3.51970941 L8.70591205,0.22562146 C8.31604759,-0.164243002 8,-0.0376302616 8,0.518665968 L8,2.51970941 C3.58,2.51970941 0,6.09970941 0,10.5197094 C0,12.0897094 0.46,13.5497094 1.24,14.7797094 L2.7,13.3197094 C2.25,12.4897094 2,11.5297094 2,10.5197094 C2,7.20970941 4.69,4.51970941 8,4.51970941 L8,6.52075285 Z M13.3,7.71970941 C13.74,8.55970941 14,9.50970941 14,10.5197094 C14,13.8297094 11.31,16.5197094 8,16.5197094 L8,14.518666 C8,13.9669575 7.68612671,13.8335827 7.29408795,14.2256215 L4,17.5197094 L7.29408795,20.8137974 C7.68395241,21.2036618 8,21.0770491 8,20.5207528 L8,18.5197094 C12.42,18.5197094 16,14.9397094 16,10.5197094 C16,8.94970941 15.54,7.48970941 14.76,6.25970941 L13.3,7.71970941 Z" id="Shape"></path>
                                     </g>
                                 </g>
                             </svg>
                        </div>
                        <span class="detail-label-text">{{ 'REPEATS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span *ngIf="task.repeat==='w'">{{ 'TASK_EVERY_WEEK' | translate }}</span>
                        <span *ngIf="task.repeat==='m'">{{ 'TASK_EVERY_MONTH' | translate }}</span>
                        <span *ngIf="task.repeat==='d'">{{ 'TASK_EVERY_DAY' | translate }}</span>
                        <span *ngIf="task.repeat==='y'">{{ 'TASK_EVERY_YEAR' | translate }}</span>
                        <span *ngIf="task.repeat==='' || task.repeat==='n' || (!task.repeat)">{{ 'TASK_NO_REPEAT' | translate }}</span>
                    </div>
                </div>
                <!-- Project -->
                <div class="table-row" *ngIf="task.project && projectList.length > 1">
                    <div class="label-table-cell">
                        <mat-icon>work</mat-icon>
                        <span class="detail-label-text">{{ 'TASK_PROJECT' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.project">{{task.project.name}}</span>
                    </div>
                </div>
                <!-- Created by -->
                <div class="table-row" *ngIf="task.author">
                  <div class="label-table-cell">
                    <div class="icon-container-block">
                        <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/user-new</title>
                            <g id="Icon/product/user-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M6,17.85 L6,19 C6,19.5522847 5.55228475,20 5,20 C4.44771525,20 4,19.5522847 4,19 L4,17.85 L4,17.85 C4,14.8766994 8.5673946,13 12.5,13 C16.3506761,13 20.8099674,14.7993182 20.9940999,17.6656036 L21,17.85 L21,19 C21,19.5522847 20.5522847,20 20,20 C19.4477153,20 19,19.5522847 19,19 L19,17.85 L19,17.85 C19,16.540378 15.79428,15.1542503 12.9670805,15.0119699 L12.7324336,15.0030208 L12.5,15 C9.64886306,15 6.18754404,16.3913943 6.00734383,17.740716 L6,17.85 Z M12.507014,3 C14.9932903,3 17,5.01382241 17,7.5 C17,9.98617759 14.9932903,12 12.507014,12 C10.0193052,12 8,9.98473341 8,7.5 C8,5.01526659 10.0193052,3 12.507014,3 Z M12.507014,5 C11.1229794,5 10,6.12073341 10,7.5 C10,8.87926659 11.1229794,10 12.507014,10 C13.8871336,10 15,8.88318908 15,7.5 C15,6.11681092 13.8871336,5 12.507014,5 Z" id="Shape" fill="#8B96A0"></path>
                            </g>
                        </svg>
                    </div>
                      <span class="detail-label-text">{{ 'CREATED_BY' | translate }}:</span>
                  </div>
                  <div class="value-table-cell">
                      <span>{{ task.author.name }}</span>
                  </div>
                </div>
                <!-- List -->
                <div class="table-row" *ngIf="task.list">
                    <div class="label-table-cell">
                        <div class="icon-container-block">
                            <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/assignment-24px</title>
                                <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
                                        <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <span class="detail-label-text">{{ 'LIST' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span class="list-span">{{task.list.name}}</span>
                    </div>
                </div>
                <!-- Location -->
                <div class="table-row" *ngIf="task.location">
                  <div class="label-table-cell">
                    <div class="icon-container-block">
                        <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/location-new</title>
                            <g id="Icon/product/location-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#8B96A0">
                                    <path d="M8,0 C12.4228571,0 16,3.443 16,7.7 C16,9.65461538 15.0835503,11.9242604 13.8710168,14.0717708 L13.5334511,14.6540989 C13.476122,14.7505394 13.4182977,14.8466454 13.3600364,14.942376 L13.0056914,15.5119246 C12.9459153,15.6059889 12.8858188,15.6995955 12.8254602,15.7927035 L12.4606281,16.3450387 L12.0923004,16.8834349 L11.7232736,17.4059213 L11.3563441,17.9105274 L10.9943086,18.3952825 L10.6399636,18.8582157 L10.2961056,19.2973566 L8.76209811,21.1029683 C8.40451496,21.5238635 7.7734336,21.575188 7.35253844,21.2176049 C7.31128664,21.1825583 7.2729485,21.1442201 7.23790189,21.1029683 L5.7038944,19.2973566 L5.184,18.6296 L4.82546017,18.1555095 L4.46062813,17.6605826 L4.09230041,17.14679 C4.03077651,17.0596952 3.96922349,16.9718965 3.90769959,16.8834349 L3.53937187,16.3450387 L3.17453983,15.7927035 L2.816,15.2284 C2.69784615,15.0385231 2.5812071,14.8469799 2.46654893,14.6540989 L2.12898316,14.0717708 C0.916449704,11.9242604 0,9.65461538 0,7.7 C0,3.443 3.57714286,0 8,0 Z M8,2 C4.66497074,2 2,4.56503434 2,7.7 C2,8.69322797 2.3458444,9.96053154 3.01409327,11.4203421 C3.64939915,12.8081876 4.54667863,14.3111997 5.63770505,15.8676171 C6.30819597,16.8241143 7.01937804,17.7553435 7.73858591,18.6369503 L8,18.952 L8.26141409,18.6369503 C8.80081999,17.9757452 9.33571138,17.2866275 9.85230757,16.5798717 L10.362295,15.8676171 C11.4533214,14.3111997 12.3506009,12.8081876 12.9859067,11.4203421 C13.6541556,9.96053154 14,8.69322797 14,7.7 C14,4.56503434 11.3350293,2 8,2 Z M8,5.5 C9.38,5.5 10.5,6.62 10.5,8 C10.5,9.38 9.38,10.5 8,10.5 C6.62,10.5 5.5,9.38 5.5,8 C5.5,6.62 6.62,5.5 8,5.5 Z" id="Combined-Shape"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                      <span class="detail-label-text">{{ 'LOCATION' | translate }}:</span>
                  </div>
                  <div class="value-table-cell">
                      <span class="list-span">{{task.location.name}}</span>
                  </div>
                </div>
                <!-- Tags -->
                <div class="table-row" *ngIf="( isMobileDevice && task.tags && task.tags.length > 0)">
                    <div class="label-table-cell tag-icon-div">
                        <div class="icon-container-block">
                            <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/tag-new</title>
                                <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z" id="Combined-Shape" fill="#8B96A0"></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="value-table-cell tag-value-div">
                        <div class="tags-list-view">
                            <span *ngFor="let tag of task.tags;  let i = index">
                                <ng-container *ngIf="i <= 4">
                                    <span class="detail-input-tag">{{ tag.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="i == 5 && !showMoreTags">
                                    <span class="view-all-text" (click)="showMoreTags = true;">
                                        <mdl-icon>more_horiz</mdl-icon>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="i > 4 && showMoreTags">
                                    <span class="detail-input-tag">{{ tag.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="(i === task.tags.length - 1) && showMoreTags">
                                    <span class="view-less-text" (click)="showMoreTags = false;">{{ 'SHOW_LESS' | translate }}</span>
                                </ng-container>
                             </span>
                        </div>
                    </div>
                </div>
                <div class="table-row" *ngIf="(!isMobileDevice && task.tags && task.tags.length > 0)">
                    <div class="label-table-cell">
                        <div class="icon-container-block">
                            <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/tag-new</title>
                                <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z" id="Combined-Shape" fill="#8B96A0"></path>
                                </g>
                            </svg>
                        </div>
                        <span class="detail-label-text">{{ 'TAGS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <div class="tags-list-view">
                            <span *ngFor="let tag of task.tags;  let i = index">
                                <ng-container *ngIf="i <= 4">
                                    <span class="detail-input-tag">{{ tag.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="i == 5 && !showMoreTags">
                                    <span class="view-all-text" (click)="showMoreTags = true;">
                                        <mdl-icon>more_horiz</mdl-icon>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="i > 4 && showMoreTags">
                                    <span class="detail-input-tag">{{ tag.name }}</span>
                                </ng-container>
                                <ng-container *ngIf="(i === task.tags.length - 1) && showMoreTags">
                                    <span class="view-less-text" (click)="showMoreTags = false;">{{ 'SHOW_LESS' | translate }}</span>
                                </ng-container>
                            </span>
                         </div>
                    </div>
                </div>
                <div class="separate-bar margin-top-bottom-10"></div>
                <div class="table-row" *ngIf="!isShowMore">
                    <div class="show-more-div" (click)="isShowMore = true;">
                        <span>{{ 'SHOW_MORE' | translate }}</span>
                        <svg width="14px" height="14px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/arrow-downward</title>
                            <g id="Icon/product/arrow-downward" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Icon-24px" transform="translate(4.000000, 4.000000)" fill="#29b6f6">
                                    <polygon id="Shape" points="16 8 14.59 6.59 9 12.17 9 0 7 0 7 12.17 1.42 6.58 0 8 8 16"></polygon>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>

                <div *ngIf="isShowMore" style="padding-top:20px;">
                    <!-- Reminder -->
                    <div class="table-row" *ngIf="task.remind_on">
                        <div class="label-table-cell">
                            <mat-icon>notifications_active</mat-icon>
                            <span class="detail-label-text">{{ 'REMINDER' | translate }}:</span>
                        </div>
                        <div class="value-table-cell">
                            <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.remind_on">{{ getDateWithFormat(task.remind_on, "MMMM dd, yyyy. HH:mm") }}</span>
                        </div>
                    </div>
                    <!-- URL -->
                    <div class="table-row" *ngIf="task.external_url">
                        <div class="label-table-cell">
                            <mat-icon>insert_link</mat-icon>
                            <span class="detail-label-text">{{ 'URL' | translate }}:</span>
                        </div>
                        <div class="value-table-cell">
                            <span class="word-break" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.external_url" [innerHTML]="linkify(task.external_url)"></span>
                        </div>
                    </div>
                    <!-- Attachment -->
                    <div class="table-row" *ngIf="task.attachments && task.attachments.length > 0">
                        <div class="label-table-cell-row">
                            <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/attachment</title>
                                <g id="Icon/product/attachment" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Icon-24px" transform="translate(2.000000, 7.000000)" fill="#8B96A0">
                                        <path d="M0,5.5 C0,2.46 2.46,0 5.5,0 L16,0 C18.21,0 20,1.79 20,4 C20,6.21 18.21,8 16,8 L7.5,8 C6.12,8 5,6.88 5,5.5 C5,4.12 6.12,3 7.5,3 L15,3 L15,5 L7.41,5 C6.86,5 6.86,6 7.41,6 L16,6 C17.1,6 18,5.1 18,4 C18,2.9 17.1,2 16,2 L5.5,2 C3.57,2 2,3.57 2,5.5 C2,7.43 3.57,9 5.5,9 L15,9 L15,11 L5.5,11 C2.46,11 0,8.54 0,5.5 L0,5.5 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                            <span class="detail-label-text">{{ 'ATTACHMENTS' | translate }}:</span>
                        </div>
                    </div>
                    <div *ngIf="task.attachments && task.attachments.length > 0">
                        <div *ngFor="let attachment of task.attachments" class="table-row">
                            <div class="attachmentListView">
                                <div class="thumbnail" (click)="openAttachment(attachment)">
                                    <img [src]="CommonUtil.getMediaUrl(CommonUtil.getMediaType(attachment.filename))">
                                </div>
                                <div class="filename-detail" (click)="openAttachment(attachment)">
                                    <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="checkAttachmentHighLight(attachment)">{{attachment.filename}}</span>
                                </div>
                                <div class="thumbnail" (click)="downloadFile(attachment)">
                                    <img [src]="CommonUtil.getFullUrl('/assets/media-icons/download.svg')">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- watchers -->
                    <div class="table-row" *ngIf="task.watchers && task.watchers.length > 0">
                      <div class="label-table-cell-row">
                          <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <title>Icon/product/visibility</title>
                              <g id="Icon/product/visibility" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <g id="Icon-24px" transform="translate(1.000000, 4.500000)" fill="#8B96A0">
                                      <path d="M11,0 C16,0 20.27,3.11 22,7.5 C20.27,11.89 16,15 11,15 C6,15 1.73,11.89 0,7.5 C1.73,3.11 6,0 11,0 Z M11,2 C7.3276702,2 4.03570887,4.0392665 2.34847366,7.18104549 L2.185,7.499 L2.207812,7.54698098 C3.80585357,10.7580378 7.05081834,12.8886127 10.7102582,12.9957618 L11,13 C14.7772535,13 18.1520927,10.8425393 19.792188,7.54698098 L19.814,7.499 L19.792188,7.45301902 C18.1941464,4.24196222 14.9491817,2.11138727 11.2897418,2.00423816 L11,2 Z M11,3.5 C13.2085695,3.5 15,5.2914305 15,7.5 C15,9.7085695 13.2085695,11.5 11,11.5 C8.7914305,11.5 7,9.7085695 7,7.5 C7,5.2914305 8.7914305,3.5 11,3.5 Z M11,5.5 C9.896,5.5 9,6.396 9,7.5 C9,8.604 9.896,9.5 11,9.5 C12.104,9.5 13,8.604 13,7.5 C13,6.396 12.104,5.5 11,5.5 Z" id="Shape"></path>
                                  </g>
                              </g>
                          </svg>
                          <span class="detail-label-text">{{ 'WATCHERS' | translate }}:</span>
                      </div>
                    </div>
                    <div *ngIf="task.watchers && task.watchers.length > 0">
                      <div *ngFor="let watcher of task.watchers" class="table-row">
                          <div class="watcherListView">
                            <vp-avatar [jid]="watcher.mail" [avatarId]="watcher.id" [user]="{ name: watcher && watcher.name ? watcher.name : '', avatarURL: watcher.avatar }"> </vp-avatar>
                            <div class="name-detail">
                              <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="checkWatcherHighLight(watcher)">{{ watcher.name }}</span>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="table-row" *ngIf="isShowMore">
                    <div class="show-more-div" (click)="isShowMore = false;">
                        <span>{{ 'SHOW_LESS' | translate }}</span>
                        <svg width="14px" height="14px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/arrow-upward</title>
                            <g id="Icon/product/arrow-upward" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Icon-24px" transform="translate(4.000000, 4.000000)" fill="#29b6f6">
                                    <polygon id="Shape" points="0 8 1.41 9.41 7 3.83 7 16 9 16 9 3.83 14.58 9.42 16 8 8 0"></polygon>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <!-- Separater Div for Efforts And Progress -->
            <div class="separater-expand-div" (click)="effortsAndProgress ? !effortsAndProgress = false: effortsAndProgress = true">
                <div class="item-icon">
                    <svg *ngIf="!effortsAndProgress" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/arrow-drop-down</title>
                        <g id="Icon/product/arrow-drop-down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(7.000000, 9.000000)" fill="#8B96A0">
                                <polygon id="Shape" points="0 0 5 5 10 0"></polygon>
                            </g>
                        </g>
                    </svg>
                    <svg *ngIf="effortsAndProgress" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/arrow-drop-up</title>
                        <g id="Icon/product/arrow-drop-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(7.000000, 9.000000)" fill="#8B96A0">
                                <polygon id="Shape" points="0 5 5 0 10 5"></polygon>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="item-title">{{ 'EFFORTS_AND_PROGRESS' | translate }}:</div>
            </div>
            <div *ngIf="effortsAndProgress" class="padding-14">
                <!-- Estimated time -->
                <div class="table-row" *ngIf="task.estimated_hours">
                    <div class="label-table-cell">
                        <mat-icon>access_time</mat-icon>
                        <span class="detail-label-text">{{ 'ESTIMATED_TIME' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.estimated_hours">{{ convertDecimalToTimeFormat(task.estimated_hours) }}</span>
                    </div>
                </div>
                <!-- Spent time -->
                <div class="table-row" *ngIf="task.spent_hours && task.estimated_hours">
                    <div class="label-table-cell">
                        <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/timer</title>
                            <g id="Icon/product/timer" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#8B96A0">
                                    <path d="M12,0 L6,0 L6,2 L12,2 L12,0 L12,0 Z M8,13 L10,13 L10,7 L8,7 L8,13 L8,13 Z M16.03,6.39 L17.45,4.97 C17.02,4.46 16.55,3.98 16.04,3.56 L14.62,4.98 C13.07,3.74 11.12,3 9,3 C4.03,3 0,7.03 0,12 C0,16.97 4.02,21 9,21 C13.98,21 18,16.97 18,12 C18,9.88 17.26,7.93 16.03,6.39 L16.03,6.39 Z M9,19 C5.13,19 2,15.87 2,12 C2,8.13 5.13,5 9,5 C12.87,5 16,8.13 16,12 C16,15.87 12.87,19 9,19 L9,19 Z" id="Shape"></path>
                                </g>
                            </g>
                        </svg>
                        <span class="detail-label-text">{{ 'TOTAL_SPENT_TIME' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span>{{ convertDecimalToTimeFormat(task.spent_hours) }}</span>
                        <span class="timelog-history-label" (click)="openTimelogHistoryDialog()">{{ 'TIMELOG_HISTORY' | translate }}</span>
                    </div>
                </div>
                <!-- Done -->
                <div class="table-row">
                    <div class="label-table-cell">
                        <div class="icon-container-block">
                            <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/timelapse</title>
                                <g id="Icon/product/timelapse" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
                                        <path d="M14.24,5.76 C13.07,4.59 11.54,4 10,4 L10,10 L5.76,14.24 C8.1,16.58 11.9,16.58 14.25,14.24 C16.59,11.9 16.59,8.1 14.24,5.76 L14.24,5.76 Z M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M10,18 C5.58,18 2,14.42 2,10 C2,5.58 5.58,2 10,2 C14.42,2 18,5.58 18,10 C18,14.42 14.42,18 10,18 L10,18 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <span class="detail-label-text">{{ 'COMPLETED' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <span [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="taskHighlightFields && taskHighlightFields.done_ratio">{{ task.done_ratio }}%</span>
                    </div>
                </div>
            </div>
            <div class="separater-expand-div" (click)="commentListExpanded ? !commentListExpanded = false: commentListExpanded = true">
                <div class="item-icon">
                    <svg *ngIf="!commentListExpanded" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/arrow-drop-down</title>
                        <g id="Icon/product/arrow-drop-down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(7.000000, 9.000000)" fill="#8B96A0">
                                <polygon id="Shape" points="0 0 5 5 10 0"></polygon>
                            </g>
                        </g>
                    </svg>
                    <svg *ngIf="commentListExpanded" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/arrow-drop-up</title>
                        <g id="Icon/product/arrow-drop-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(7.000000, 9.000000)" fill="#8B96A0">
                                <polygon id="Shape" points="0 5 5 0 10 5"></polygon>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="item-title">{{ 'COMMENTS' | translate }}: <span class="item-count" *ngIf="!commentListExpanded && task.comments">{{ task.comments.length }}</span></div>
            </div>
            <div id="task-edit-comment-section" class="comment_section" *ngIf="commentListExpanded && task.comments">
                <div *ngFor="let comment of task.comments; let i = index" class="comment_detail">
                    <vp-avatar [avatarId]="comment.user.id" [user]="{ name: comment.user && comment.user.name ? comment.user.name : '', avatarURL: comment.userAvatar }"> </vp-avatar>
                    <div class="comment_detail--body" [style.animation-name]="getTextHightLightPropertyName(this.task.priority)" [class.highlight]="checkCommentHighLight(comment)">
                        <div class="comment_detail--body__meta">
                            <span class="comment_detail--body__meta--username">{{comment.user.name}}</span>
                            <span class="comment_detail--body__meta--date align-right">{{ getDateWithFormat(comment.created_on, "MMM dd, yyyy") }}</span>
                        </div>
                        <div class="comment_detail--body__text">
                            <p *ngIf="!comment.isEditMode" [innerHTML]="comment.notes"></p>
                            <div class="edit-comment" *ngIf="comment.isEditMode">
                                <textarea [vpMention]="userMentionList" [mentionConfig]="{ triggerChar:'@', maxItems:100, labelKey:'label', mentionSelect: onMentionSelect }" #editCommentInput id="edit-comment-{{i}}" class="edit-comment-input ng-dirty" (input)="applySizeEditComment(editCommentInput, i)"
                                    (change)="applySizeEditComment(editCommentInput,i)"></textarea>
                                <div class="edit-comment-buttons">
                                    <span (click)="resetComment(comment)" class="cancel-btn">{{ 'CANCEL' | translate }}</span>
                                    <span (click)="updateComment(comment, editCommentInput.value)" class="save-btn">{{ 'SAVE' | translate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="comment_detail--body__edited">
                            <span *ngIf="!comment.isEditMode && comment.isEdited">{{ 'EDITED' | translate }}</span>
                        </div>
                    </div>
                    <div class="comment_detail--edit__button">
                        <svg *ngIf="authUser?.id === comment?.user.id" (click)="editComment(comment, i)" width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/edit</title>
                            <g id="Icon/product/edit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Icon-24px" transform="translate(3.000000, 2.997500)" fill="#8B96A0">
                                    <path d="M0,14.2525 L0,18.0025 L3.75,18.0025 L14.81,6.9425 L11.06,3.1925 L0,14.2525 L0,14.2525 Z M17.71,4.0425 C18.1,3.6525 18.1,3.0225 17.71,2.6325 L15.37,0.2925 C14.98,-0.0975 14.35,-0.0975 13.96,0.2925 L12.13,2.1225 L15.88,5.8725 L17.71,4.0425 L17.71,4.0425 Z" id="Shape"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div *ngIf="!task.comments.length" class="no-comments">{{ 'NO_COMMENTS' | translate }}!</div>
            </div>
        </div>
        <div class="detail-info-table" [class.de-german-edit]="isDe" *ngIf="isEditMode">
            <!-- Priority -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'PRIORITY' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                    <mat-select
                        class="mdl-selection-option-only-background"
                        [(ngModel)]="selectedPriorityOption"
                        (selectionChange)="onPrioritySelect($event)"
                        disableOptionCentering
                        panelClass="mat-select-dropdown-below"
                    >
                        <mat-option *ngFor="let priority of priorityList" [value]="priority.id" [style.color]="getPriorityColor(priority)">
                            {{ priority.name | uppercase | translate }}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- Assign to -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'ASSIGNED_TO' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field" >
                        <input type="text" #assigneeTrigger matInput [formControl]="assigneeCtrl" [matAutocomplete]="assignee" readonly [matMenuTriggerFor]="assignee_menu">
                        <mat-autocomplete class="assignee-list" #assignee="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let user of filteredUsers | async" [value]="user" class="option-list-item">
                                <vp-avatar [jid]="user.mail" [avatarId]="user.id" [user]="{ name: user && user.name ? user.name : '', avatarURL: user.avatar }"> </vp-avatar>
                                <span class="assignee-text">{{user.name}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                      <mat-menu #assignee_menu="matMenu" class="assignee-list-block" (closed)="cancelSaveAssignee()">
                        <span (click)="$event.stopPropagation()">
                            <vnc-input size="m" (click)="preventClose($event)" [form]="searchControl" clearTooltip="{{ 'CLEAR' | translate}}" [showClear]="true" [showLabel]="false" [focused]="true" placeholder="Search"></vnc-input>
                            <div tabindex="-1" class="list-sec-assignee cdk-virtual-scroll-orientation-vertical">
                               <div  (click)="selectAssignee($event, item.id, item)" *ngFor="let item of filteredOptions" class="assignee-list-item-d">
                                   <div class="label-avatar">
                                       <vp-avatar [jid]="item.mail" [avatarId]="item.id" [user]="{ name: item && item.name ? item.name : '', avatarURL: item.avatar }"> </vp-avatar>
                                       <span class="assignee-text">{{item.label}}</span>
                                   </div>
                                   <ng-container [ngTemplateOutlet]="checkIcon" *ngIf="item.isSelected">
                                   </ng-container>
                               </div>
                            </div>
                        </span>

                         <div class="buttons-block-assignee">
                            <div>

                            </div>
                            <div>
                                <vnc-button [height]="'small'"
                                [shape]="'rectangle'"
                                [type]="'link'"
                                [label]="'CANCEL' | translate"
                                (onClick)="cancelSaveAssignee()"
                                class="vnc-default"></vnc-button>
                                <vnc-button [height]="'small'"
                                [shape]="'rectangle'"
                                [type]="'primary'"
                                [label]="'SAVE' | translate"
                                (onClick)="saveAssignee()"
                                ></vnc-button>
                            </div>
                         </div>
                      </mat-menu>
                </div>
            </div>
            <div class="margin-top-bottom-10"></div>
            <!-- Description -->
            <div class="table-row">
                <div class="detail__toolbar">
                    <vp-vnctask-toolbar #taskToolbar (isToolbarOpen)="toolbarIsOpen($event)" [content]="taskCpy.description"></vp-vnctask-toolbar>
                </div>
            </div>
            <div class="margin-top-bottom-10"></div>
            <!-- Start Date -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'TASK_START_DATE' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <!-- <div *ngIf="!taskCpy.start_date || taskCpy.start_date == null" #startDateInput class="edit-task-date-input-field" [matMenuTriggerFor]="startDateMenu">
                        {{ 'NONE' | translate }}
                        <mat-icon class="edit-task-date-mat-icon">arrow_drop_down</mat-icon>
                    </div>
                    <div *ngIf="taskCpy.start_date || taskCpy.start_date != null" #startDateInput class="edit-task-date-input-field" [matMenuTriggerFor]="startDateMenu">{{ getDateWithFormat(taskCpy.start_date, "dd MMM. y") }}
                        <mat-icon class="edit-task-date-mat-icon">arrow_drop_down</mat-icon>
                    </div> -->
                    <vnc-datepicker class="start-date" label="{{ 'TASK_START_DATE' | translate}}"
                        singleDatePickerId="start-date-picker"
                        [placeholder]="'TASK_START_DATE' | translate" [showCancel]="true" [showDropdowns]="true" [drops]="'down'"
                        [showTimePicker]="false" [linkedCalendars]="false" [lang]="lang" applyLabel="{{ 'APPLY' | translate}}"
                        cancelLabel="{{ 'CANCEL' | translate}}" [format]="'DD.MM.YYYY'" [singleSelected]="{'startDate':taskCpy?.start_date}"
                        [singleDatePicker]="true" (datesUpdatedEvent)="editDateSelect($event, 'startDateSet')">
                    </vnc-datepicker>

                    <!-- <mat-menu #startDateMenu="matMenu" class="edit-task-date-matmenu">
                        <button mat-menu-item class="edit-task-date-matmenu-item" *ngFor="let startdate of startDateItems" (click)="startDateSelectItem(startdate)">
                            {{startdate.name}}
                            <span *ngIf="startdate.date!== null">{{startdate.date | vpLocaleDate: 'dd MMM.'}}</span>
                            <mdl-icon *ngIf="startdate.date==null">event_busy</mdl-icon>
                        </button>
                        <button mat-menu-item class="pick-date-icon" (click)="editDateClick('startDateSet')">
                            <span>{{ 'PICK_START_DATE' | translate }}</span>
                            <mdl-icon>today</mdl-icon>
                        </button>
                    </mat-menu> -->
                </div>
            </div>
            <!-- Due Date -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'TASK_DUE_DATE' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <vnc-datepicker class="start-date" label="{{ 'TASK_DUE_DATE' | translate}}" [placeholder]="'TASK_DUE_DATE' | translate"
                        singleDatePickerId="due-date-picker" [showCancel]="true" [showDropdowns]="true" [drops]="'down'" [showTimePicker]="false" [linkedCalendars]="false"
                        [pickerOpen]="pickerTwo" [lang]="lang" applyLabel="{{ 'APPLY' | translate}}" cancelLabel="{{ 'CANCEL' | translate}}"
                        [singleSelected]="{'startDate':taskCpy?.due_date}" [singleDatePicker]="true" [format]="'DD.MM.YYYY'"
                        (datesUpdatedEvent)="editDateSelect($event, 'dueDateSet')">
                    </vnc-datepicker>
                    <!-- <div *ngIf="!taskCpy.due_date || taskCpy.due_date == null" #dueDateInput class="edit-task-date-input-field" [matMenuTriggerFor]="dueDateMenu">
                        {{ 'NONE' | translate }}
                        <mat-icon class="edit-task-date-mat-icon">arrow_drop_down</mat-icon>
                    </div>
                    <div *ngIf="taskCpy.due_date || taskCpy.due_date != null" #dueDateInput class="edit-task-date-input-field" [matMenuTriggerFor]="dueDateMenu">{{ getDateWithFormat(taskCpy.due_date, "dd MMM. y") }}
                        <mat-icon class="edit-task-date-mat-icon">arrow_drop_down</mat-icon>
                    </div>
                    <mat-menu #dueDateMenu="matMenu" class="edit-task-date-matmenu">
                        <button mat-menu-item class="edit-task-date-matmenu-item" *ngFor="let duedate of dueDateItems" (click)="dueDateSelectItem(duedate)">
                            {{duedate.name}}
                            <span *ngIf="duedate.date!== null">{{duedate.date | vpLocaleDate: 'dd MMM.'}}</span>
                            <mdl-icon *ngIf="duedate.date==null">event_busy</mdl-icon>
                        </button>
                        <button mat-menu-item class="pick-date-icon" (click)="editDateClick('dueDateSet')">
                            <span>{{ 'PICK_DUE_DATE' | translate }}</span>
                            <mdl-icon>event</mdl-icon>
                        </button>
                    </mat-menu> -->
                </div>
            </div>
            <!-- Status -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'STATUS' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                    <mat-select class="mdl-selection-option" [(ngModel)]="selectedStatusOption" (selectionChange)="onStatusSelect($event)">
                        <mat-option *ngFor="let status of required_statuses" [value]="status.id">
                            {{status.displayName}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- Repeats -->
            <div class="table-row table-row-with-padding">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'REPEATS' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                    <mat-select class="mdl-selection-option" [(ngModel)]="selectedRepeatOption" (selectionChange)="onRepeatsSelect($event)">
                        <mat-option *ngFor="let repeat of repeatItems" [value]="repeat.value">
                            {{repeat.name}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- Project -->
            <div class="table-row padding-left-right-15" *ngIf="projectList.length > 1">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'TASK_PROJECT' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                        <input type="text" #projectTrigger matInput [formControl]="projectCtrl" [matAutocomplete]="project">
                        <mat-autocomplete class="project-list" #project="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onProjectSelect($event)">
                            <mat-option *ngFor="let project of filteredProjects | async" [value]="project" class="option-list-item">
                                <span>{{project.name}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <!-- List -->
            <div class="table-row padding-left-right-15">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'LIST' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <mat-form-field class="form-field">
                        <input type="text" #listTrigger matInput [formControl]="listCtrl" [matAutocomplete]="list">
                        <mat-autocomplete class="project-list" #list="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let list of filteredLists | async" [value]="list" class="option-list-item">
                                <span class="list-span">{{list.name}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <!-- Location -->
            <div class="table-row padding-left-right-15">
              <div class="label-table-cell">
                  <span class="detail-label-text">{{ 'LOCATION' | translate }}:</span>
              </div>
              <div class="value-table-cell">
                  <mat-form-field class="form-field">
                      <input type="text" #locationTrigger matInput [formControl]="locationCtrl" [matAutocomplete]="location">
                      <mat-autocomplete class="project-list" #location="matAutocomplete" [displayWith]="displayFn">
                          <mat-option *ngFor="let location of filteredLocations | async" [value]="location" class="option-list-item">
                              <span class="list-span">{{location.name}}</span>
                          </mat-option>
                      </mat-autocomplete>
                  </mat-form-field>
              </div>
            </div>
            <!-- Estimate Time-->
            <div class="table-row padding-left-right-15">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'ESTIMATED_TIME' | translate }}:</span>
                </div>
                <div class="value-table-cell" *ngIf="spentHours == '00' && spentMinutes == '00'">
                    <input class="task-time-input" [(ngModel)]="estimatedHours" (keydown)="validateNumber($event, estimatedHours)" type="text" maxlength="2" inputmode="numeric" pattern="[0-9]*">
                    <span>:</span>
                    <input class="task-time-input" [(ngModel)]="estimatedMinutes" (keydown)="validateNumber($event, estimatedMinutes)" type="text" maxlength="2" inputmode="numeric" pattern="[0-9]*">
                    <span class="detail-label-text">(hh:mm)</span>
                </div>
                <div class="value-table-cell" *ngIf="spentHours != '00' || spentMinutes != '00'">
                    <span>{{ convertDecimalToTimeFormat(task.estimated_hours) }}</span>
                </div>
            </div>
            <!-- Spent Time-->
            <div class="table-row padding-left-right-15" *ngIf="taskCpy?.estimated_hours">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'TOTAL_TIME' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <span> {{ spentHours }}</span>
                    <span>:</span>
                    <span> {{ spentMinutes }}</span>
                    <span class="log-time-label" (click)="openLogTimeDialog()">{{ 'LOG_TIME' | translate }}</span>
                </div>
            </div>
            <!-- Done -->
            <div class="table-row padding-left-right-15">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'DONE' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                   <mat-form-field class="form-field">
                    <mat-select class="mdl-selection-option" [(ngModel)]="selectedDoneOption" (selectionChange)="onDoneSelect($event)">
                        <mat-option *ngFor="let percentage of done_percentages" [value]="percentage">
                            {{ percentage }}%
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="separate-bar margin-top-bottom-10"></div>
            <!-- Reminder -->
            <div class="table-row  table-row-with-padding" *ngIf="taskCpy?.remind_on">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'REMINDER' | translate }}:</span>
                </div>
                <div class="value-table-cell">
                    <div>
                        <span class="cursor-pointer" (click)="openReminderDialog()">{{ getDateWithFormat(taskCpy.remind_on, "MMMM dd, yyyy. HH:mm") }}</span>
                        <svg class="cursor-pointer align-right" (click)="removeReminder()" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/close-new</title>
                            <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <!-- URL -->
            <div class="table-row  table-row-with-padding" *ngIf="taskCpy?.external_url || showExternalUrl">
                <div class="label-table-cell">
                    <span class="detail-label-text">{{ 'URL' | translate }}:</span>
                </div>
                <div class="value-table-cell" [formGroup]="externalURLForm">
                    <mat-form-field class="external-form-field">
                        <input type="text" autocomplete="external_url" formControlName="external_url" matInput pattern="^(?:(h|H)(t|T)(t|T)(p|P)(s|S)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+%;=.]+$">
                        <mat-error *ngIf="externalURLForm.get('external_url').errors?.pattern">
                            {{ 'URL_INVALID_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- Attachment -->
            <div class="table-row-with-padding">
                <div class="table-row" *ngIf="taskCpy.attachments && taskCpy.attachments.length > 0 || fileUploads.length > 0">
                    <div class="label-table-cell">
                        <span class="detail-label-text">{{ 'ATTACHMENTS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell add-attachment-info" (click)="openAttachDialog()">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/add</title>
                        <g id="Icon/product/add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(5.000000, 5.000000)" fill="#8B96A0">
                                <polygon id="Shape" points="14 8 8 8 8 14 6 14 6 8 0 8 0 6 6 6 6 0 8 0 8 6 14 6"></polygon>
                            </g>
                        </g>
                    </svg>
                      <span class="label-text">{{ 'ADD_ATTACHMENTS' | translate }}</span>
                  </div>
                </div>
                <div *ngFor="let attachment of taskCpy.attachments" class="table-row">
                    <div class="attachmentListView isEditMode">
                        <div class="thumbnail">
                            <img [src]="CommonUtil.getMediaUrl(CommonUtil.getMediaType(attachment.filename))">
                        </div>
                        <div class="filename">{{attachment.filename}}</div>
                        <div class="clearBtn">
                            <svg *ngIf="attachment.deletable" (click)="removeAttachments(attachment, true)" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/close-new</title>
                                <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div *ngFor="let attachment of fileUploads" class="table-row">
                    <div class="attachmentListView isEditMode">
                        <div class="thumbnail">
                            <img [src]="CommonUtil.getMediaUrl(CommonUtil.getMediaType(attachment.filename))">
                        </div>
                        <div class="filename">{{attachment.filename}}</div>
                        <div class="clearBtn">
                            <svg (click)="removeAttachments(attachment, false)" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/close-new</title>
                                <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tags -->
            <div class="table-row-with-padding" *ngIf="!isMobileDevice">
                <div class="table-row" *ngIf="!isMobileDevice">
                    <div class="label-table-cell">
                        <span class="detail-label-text">{{ 'TAGS' | translate }}:</span>
                    </div>
                    <div class="value-table-cell">
                        <div class="tags-list-view">
                            <span *ngFor="let tag of taskCpy.tags" class="edit-input-tag">
                                <span class="edit-input-tag-name">{{tag.name}}</span>
                                <i class="material-icons" (click)="removeTag(tag)">close</i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-row table-row-with-padding">
                <div class="tags-list-view">
                    <div class="add-tag-div">
                        <textarea class="filter-search-textfield add-tag-input ng-dirty" [(ngModel)]="tagText" placeholder="{{ 'ADD_TAG' | translate }}"
                            (keydown.enter)="addTag($event);false" (keydown.space)="false" id="task-edit-add-tag-textfield" rows="1" minlength="2" (input)="searchTags($event)" #tagInput [matAutocomplete]="tag">
                        </textarea>
                        <button mat-button mat-fab *ngIf="(tagText ? tagText.length >= 2 : false)" class="add-tag-check" (click)="addTag($event)">
                            <mat-icon class="check-icon-tag">done</mat-icon>
                        </button>
                    </div>
                    <span class="tag-msg-span">{{ 'ADD_TAG_MSG' | translate }}</span>
                    <mat-autocomplete class="tag-list" #tag="matAutocomplete" [displayWith]="displayValue.bind(this)">
                        <mat-option *ngFor="let tag of searchTagsList" [value]="tag.name">
                            <span>{{ (tag) ? tag.name : ('NO_NAME' | translate) }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="table-row-with-padding" *ngIf="isMobileDevice">
                <div class="table-row" *ngIf="(taskCpy.tags && taskCpy.tags.length > 0)">
                    <div class="tags-list-view margin-left-35">
                        <span *ngFor="let tag of taskCpy.tags" class="edit-input-tag">
                            <span class="edit-input-tag-name">{{tag.name}}</span>
                            <i class="material-icons" (click)="removeTag(tag)">close</i>
                        </span>
                    </div>
                </div>
            </div>
            <!-- watchers -->
            <div class="table-row-with-padding">
              <div class="table-row" *ngIf="taskCpy.watchers && taskCpy.watchers.length > 0 || selectedWatcherList.length > 0">
                  <div class="label-table-cell">
                      <span class="detail-label-text">{{ 'WATCHERS' | translate }}:</span>
                  </div>
                  <div class="value-table-cell add-attachment-info" (click)="openWatcherDialog()">
                    <mat-icon>add</mat-icon>
                    <span class="label-text">{{ 'ADD_WATCHERS' | translate }}</span>
                </div>
              </div>
              <div *ngFor="let watcher of taskCpy.watchers" class="table-row">
                  <div class="watcherListView isEditMode">
                      <vp-avatar [jid]="watcher.mail" [avatarId]="watcher.id" [user]="{ name: watcher && watcher.name ? watcher.name : '', avatarURL: watcher.avatar }"> </vp-avatar>
                      <div class="name">{{ watcher.name }}</div>
                      <div class="clearBtn">
                          <svg (click)="removeWatchers(watcher, true)" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/close-new</title>
                            <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
                            </g>
                        </svg>
                      </div>
                  </div>
              </div>
              <div *ngFor="let watcher of selectedWatcherList" class="table-row">
                  <div class="watcherListView isEditMode">
                      <vp-avatar [jid]="watcher.mail" [avatarId]="watcher.id" [user]="{ name: watcher && watcher.name ? watcher.name : '', avatarURL: watcher.avatar }"> </vp-avatar>
                      <div class="name">{{ watcher.name }}</div>
                      <div class="clearBtn">
                          <svg (click)="removeWatchers(watcher, false)" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Icon/product/close-new</title>
                            <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
                            </g>
                        </svg>
                      </div>
                  </div>
              </div>
            </div>
            <div class="separate-bar margin-top-bottom-10"></div>
            <div class="detail-add-fields">
                <span (click)="openAddFieldDialog()">{{ 'ADD_FIELDS' | uppercase | translate }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="!isEditMode && commentListExpanded" class="add-comment-wrapper">
        <div class="add-comment">
            <div class="add-comment--textbox">
                <textarea [vpMention]="userMentionList" [mentionConfig]="{ triggerChar:'@', maxItems:100, labelKey:'label', mentionSelect: onMentionSelect }" id="task-edit-comment-textfield" class="comment-input ng-dirty" #commentInput [(ngModel)]="commentText" (input)="inputHandler($event)"
                    (change)="changeValue($event)" (click)="setAddCommentFocus()" placeholder="{{ 'ENTER_A_COMMENT' | translate }}"></textarea>
            </div>
            <i id="task-edit-comment-send" class="add-comment--btn material-icons" [class.disabled]="!commentText" (click)="addComment()">send</i>
        </div>
    </div>
    <div *ngIf="isEditMode" [class.with-toolbar]="isToolbarOpen" class="task__detail-footer">
        <button class="negative" (click)="backToDetail()"> {{ 'CANCEL' | translate }} </button>
        <button class="primary" (click)="applyChanges()" [disabled]="applying || !externalURLForm.valid"> {{ 'SAVE' | translate }} </button>
    </div>
</div>
<mat-menu #taskDetailHeaderPopover="matMenu" xPosition="before">
    <div class="task-detail-header-menu-popover">

        <mat-list role="list">
            <mat-list-item role="listitem" class="menu-item" (click)="onEdit(task)">
                <div class="icon-container-block">
                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/edit</title>
                        <g id="Icon/product/edit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(3.000000, 2.997500)" fill="#000000">
                                <path d="M0,14.2525 L0,18.0025 L3.75,18.0025 L14.81,6.9425 L11.06,3.1925 L0,14.2525 L0,14.2525 Z M17.71,4.0425 C18.1,3.6525 18.1,3.0225 17.71,2.6325 L15.37,0.2925 C14.98,-0.0975 14.35,-0.0975 13.96,0.2925 L12.13,2.1225 L15.88,5.8725 L17.71,4.0425 L17.71,4.0425 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                </div>
              {{'EDIT_TASK' | translate}}
            </mat-list-item>
            <mat-list-item role="listitem" class="menu-item" (click)="onCompleteTask()">
                <div class="icon-container-block">
                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/check-circle</title>
                        <g id="Icon/product/check-circle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#000000">
                                <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M8,15 L3,10 L4.41,8.59 L8,12.17 L15.59,4.58 L17,6 L8,15 L8,15 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                </div>
              {{'COMPLETE' | translate}}
            </mat-list-item>
            <mat-list-item role="listitem" class="menu-item" (click)="onDuplicateTask()">
                <div class="icon-container-block">
                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/content-copy</title>
                        <g id="Icon/product/content-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(3.000000, 2.000000)" fill="#000000">
                                <path d="M16,3 C17.1,3 18,3.9 18,5 L18,5 L18,19 C18,20.1 17.1,21 16,21 L16,21 L5,21 C3.9,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.9,3 5,3 L5,3 Z M16,5 L5,5 L5,19 L16,19 L16,5 Z M11.9359236,0 C12.4882083,0 12.9359236,0.44771525 12.9359236,1 C12.9359236,1.51283584 12.5498834,1.93550716 12.0525447,1.99327227 L11.9359236,2 L4.33333333,2 C3.09836327,2 2.08747935,2.95942684 2.00538306,4.17357901 L2,4.33333333 L2,16.0165979 C2,16.5688826 1.55228475,17.0165979 1,17.0165979 C0.487164161,17.0165979 0.0644928393,16.6305577 0.00672773133,16.133219 L0,16.0165979 L0,4.33333333 C0,2.01048865 1.82765421,0.11451548 4.12337942,0.00499702767 L4.33333333,0 L11.9359236,0 Z" id="Combined-Shape"></path>
                            </g>
                        </g>
                    </svg>
                </div>
              {{'CLONE_TASK' | translate}}
            </mat-list-item>
            <mat-list-item role="listitem" class="menu-item" (click)="onDeleteTask()">
                <div class="icon-container-block">
                    <svg width="22px" height="22px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/delete-new</title>
                        <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#000000"></path>
                        </g>
                    </svg>
                </div>
              {{'DELETE_TASK' | translate}}
            </mat-list-item>
        </mat-list>
    </div>
</mat-menu>
<ng-template #checkIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon id="Shape" fill="#337CBD" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
        </g>
    </svg>

</ng-template>